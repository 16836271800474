import classNames from 'classnames';

import continentsData from 'src/data/continents.json';

import Continent from './Continent';

import styles from './styles.module.scss';

const continentsByName = continentsData.continents;

type ContinentNames = keyof typeof continentsByName;

const continentNames = Object.keys(continentsByName) as ContinentNames[];

const visitedLinksByContinentName: Partial<Record<ContinentNames, string>> = {
  northAmerica: '/travel/north-america/',
  southAmerica: '/travel/south-america/',
  europe: '/travel/europe/',
  africa: '/travel/africa/',
  asia: '/travel/asia/',
};

export default function ContinentsMap() {
  return (
    <svg
      className={classNames('continents-map', styles['continents-map'])}
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width='468pt'
      height='239pt'
      viewBox='0 0 468 239'
    >
      {continentNames.map((continentName) => {
        const paths = continentsByName[continentName];
        return (
          <Continent
            key={continentName}
            slug={continentName}
            isVisited={continentName in visitedLinksByContinentName}
            link={visitedLinksByContinentName[continentName]}
            transform='translate(0,239) scale(0.016963,-0.016963)'
          >
            {paths.map((path, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <path key={i} d={path} />
            ))}
          </Continent>
        );
      })}
    </svg>
  );
}
