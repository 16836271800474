import Link from 'next/link';
import { useRouter } from 'next/router';
import { PostTripsSidebarContentQuery } from 'src/generated';

type Props = {
  post: NonNullable<PostTripsSidebarContentQuery['blogPostsList']>[number];
};

export default function Post({ post }: Props) {
  const router = useRouter();

  const { urlPath } = post.nodePtr || {};
  const isActive = urlPath?.startsWith(router.asPath);
  return (
    <li>
      {urlPath ? (
        <Link href={urlPath} className={isActive ? 'active' : undefined}>
          {post.title}
        </Link>
      ) : (
        post.title
      )}
    </li>
  );
}
