import { useEffect } from 'react';
import { parseISO } from 'date-fns';
import { useQuery } from '@apollo/client';
import Link from 'next/link';

import Box from 'src/components/PostSidebar/components/Box';
import { getFormattedDateRange, useLatestRef } from 'src/utils';
import SizedImage from 'src/components/SizedImage';
import {
  PostTripsSidebarContentDocument,
  TripSidebarTripFieldsFragment,
} from 'src/generated';
import horsemanImageSizes from 'src/horsemanImageSizes';

import Post from './Post';
import NextPrevItem from './NextPrevItem';

import styles from './styles.module.scss';

type Props = {
  onSizeChange: () => void;
  trip: TripSidebarTripFieldsFragment;
};

export default function Trip({ onSizeChange, trip }: Props) {
  const { data } = useQuery(PostTripsSidebarContentDocument, {
    variables: { tripId: trip.nodePtrId },
  });

  const latestOnSizeChangeRef = useLatestRef(onSizeChange);
  useEffect(() => {
    latestOnSizeChangeRef.current();
  }, [data, latestOnSizeChangeRef]);

  // const router = useRouter();

  // useEffect(() => {
  //   // TODO: who knows if this is still needed...
  //   document.activeElement?.blur();
  // }, [router.asPath]);

  const posts = data?.blogPostsList;

  const next = data?.horsemannodesNode?.after?.[0];
  const previous = data?.horsemannodesNode?.before?.[0];

  return (
    <Box className={styles.box}>
      {trip.featuredImage && (
        <div className={styles.image}>
          <SizedImage
            image={trip.featuredImage}
            size={[
              horsemanImageSizes['BlogTrip.featuredImage'].default
                .post_trips_sidebar,
              horsemanImageSizes['BlogPost.featuredImage'].default['3x2'],
              horsemanImageSizes['BlogPost.body'].image.blog_image,
              horsemanImageSizes['BlogTrip.featuredImage'].default[
                'taxonomy-overview-card'
              ],
            ]}
            title={null}
            // onLoad={this.handleImageLoad}
            sizes={[
              '(min-width: 112.5rem) 27rem',
              '(min-width: 87.5rem) 22rem',
              '(min-width: 50rem) 17rem',
              '100vw',
            ]}
          />
        </div>
      )}

      <h4>
        This post is part of our{' '}
        <em>
          {trip.nodePtr?.urlPath ? (
            <Link href={trip.nodePtr.urlPath} passHref>
              {trip.name}
            </Link>
          ) : (
            trip.name
          )}
        </em>{' '}
        trip from{' '}
        {getFormattedDateRange(
          parseISO(trip.startDate),
          parseISO(trip.endDate),
        )}
      </h4>

      {posts && posts.length > 1 && (
        <div className={styles.posts}>
          <h5>More from this trip:</h5>
          <ul>
            {posts.map((post) => (
              <Post key={post.nodePtrId} post={post} />
            ))}
          </ul>
        </div>
      )}

      {(next || previous) && (
        <div className={styles['next-prev']}>
          {next && <NextPrevItem node={next} isNext />}
          {previous && <NextPrevItem node={previous} />}
        </div>
      )}

      {trip.nodePtr?.urlPath && (
        <Link href={trip.nodePtr.urlPath} passHref className={styles.view}>
          View trip →
        </Link>
      )}
    </Box>
  );
}
