import Link from 'next/link';

import Box from 'src/components/PostSidebar/components/Box';

import ContinentsMap from './Map';

import LatestPlaces from './LatestPlaces';

export default function Destinations() {
  return (
    <Box className='destinations'>
      <h2 className='header'>
        <Link href='/travel/' passHref>
          Destinations
        </Link>
      </h2>

      <ContinentsMap />

      <LatestPlaces />
    </Box>
  );
}
