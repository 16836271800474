import React, { ForwardedRef, forwardRef } from 'react';
import classNames from 'classnames';
import { useQuery } from '@apollo/client';

import { PostTripsSidebarDocument } from 'src/generated';

import Trip from './Trip';

import styles from './styles.module.scss';

export type Props = {
  fixedBottom?: number | false | null;
  isFixed?: boolean;
  onSizeChange: () => void;
  postId: string;
};

function PostTripsInner(
  { isFixed, fixedBottom, postId, onSizeChange }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { data } = useQuery(PostTripsSidebarDocument, {
    variables: { postId },
  });

  const post = data?.blogPost;

  if (!post) return null;

  const trips = post.blogPostTripsByPostIdList;

  if (!trips?.length) return null;

  return (
    <div
      className={classNames(styles['post-trips'], {
        fixed: isFixed,
        'fixed-bottom': !!fixedBottom,
      })}
      style={fixedBottom ? { bottom: fixedBottom } : undefined}
      ref={ref}
    >
      {trips.map((blogPostTrip) => {
        const { trip } = blogPostTrip;
        if (!trip) return null;
        return (
          <Trip key={blogPostTrip.id} trip={trip} onSizeChange={onSizeChange} />
        );
      })}
    </div>
  );
}

export default forwardRef(PostTripsInner);
