import { useContext } from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import { useQuery } from '@apollo/client';

import { SidebarQueryDocument } from 'src/generated';
import { styleVars } from 'src/config/siteInfo';
import SizedImage from 'src/components/SizedImage';
import SocialLinks from 'src/components/SocialLinks';
import SubscribeLinks from 'src/components/SubscribeLinks';
import horsemanImageSizes from 'src/horsemanImageSizes';
import { layoutDataContext } from 'src/utils/LayoutData';

import Box from './Box';

import styles from './styles.module.scss';

// class About extends Component {
//   static propTypes = {
//     pageRequest: PropTypes.func.isRequired,
//     pagesById: PropTypes.object.isRequired,
//     pageIdsBySlug: PropTypes.object.isRequired,
//     mediaById: PropTypes.object.isRequired,
//   };

//   componentDidMount() {
//     this.props.pageRequest({ slug: 'about' });
//   }

//   render() {}
// }

export default function About() {
  const { isLoaded } = useContext(layoutDataContext);
  const { data } = useQuery(SidebarQueryDocument, { skip: !isLoaded });
  const page = data?.aboutPage?.[0];
  const link = page?.nodePtr?.urlPath ?? '/about/';
  return (
    <Box className={classNames('about', styles.about)}>
      {page?.featuredImage ? (
        <div className={styles['featured-image']}>
          <Link href={link} passHref>
            <SizedImage
              image={page?.featuredImage}
              size={
                horsemanImageSizes['PagesPage.featuredImage'].default['3x2']
              }
              sizes={styleVars.sidebarSizes.join(', ')}
              title={null}
            />
          </Link>
        </div>
      ) : null}

      <h2 className={styles.header}>
        <Link href={link} passHref>
          About Us
        </Link>
      </h2>

      <h3>Follow us</h3>
      <SocialLinks className={styles.links} />

      <h3>Subscribe</h3>
      <SubscribeLinks className={styles.links} />
    </Box>
  );
}
