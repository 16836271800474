import React from 'react';
import Link from 'next/link';

import { styleVars } from 'src/config/siteInfo';
import SizedImage, { SizedImageData } from 'src/components/SizedImage';
import horsemanImageSizes from 'src/horsemanImageSizes';

import styles from './styles.module.scss';

type Props = {
  link: string;
  image?: SizedImageData | null;
  name: string;
};

export default function RibbonLink({ link, image, name }: Props) {
  return (
    <li>
      <Link href={link} passHref>
        {image && (
          <SizedImage
            image={image}
            size={
              horsemanImageSizes['BlogPlace.featuredImage'].default[
                'taxonomy-overview-card'
              ]
            }
            sizes={styleVars.sidebarSizes.join(', ')}
            title={null}
          />
        )}
        <div className={styles.name} title={name}>
          {name}
        </div>
      </Link>
    </li>
  );
}
