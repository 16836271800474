import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { throttle } from 'lodash';
import classNames from 'classnames';

import About from './components/About';
import Destinations from './components/Destinations';
import PostTrips from './components/PostTrips';
import PostTripsSpacer from './components/PostTrips/Spacer';

import styles from './styles.module.scss';

enum PostTripsPosition {
  Top,
  Bottom,
}

type Props = {
  hasAbout?: boolean;
  hasTrips?: boolean;
  isSinglePost?: boolean;
  postId?: string;
};

export default function PostSidebar({
  hasAbout = true,
  hasTrips = false,
  isSinglePost = false,
  postId,
}: Props) {
  const [postTripsPosition, setPostTripsPosition] = useState(
    PostTripsPosition.Top,
  );
  const [postTripsHeight, setPostTripsHeight] = useState<number | null>();
  const [postTripsFixed, setPostTripsFixed] = useState(false);
  const [postTripsFixedBottom, setPostTripsFixedBottom] = useState<
    number | false | null | undefined
  >(false);

  const postTripsRef = useRef<HTMLDivElement>(null);
  const postTripsHeightRef = useRef<number | null>();
  const postTripsBottomRef = useRef<number | null>();
  const postTripsFixedTopRef = useRef<number | null>();
  const postTripsMarginRef = useRef<number | null>();
  const viewportWidthRef = useRef<number | null>();
  const navHeightRef = useRef<number | null>();
  const contentBottomRef = useRef<number | null>();
  const nextPrevHeightRef = useRef<number | null>();

  const setPostTripsDimensions = useCallback(() => {
    if (!postId) return;

    const postTripsEl = postTripsRef.current;
    const postTripsRect = postTripsEl && postTripsEl.getBoundingClientRect();

    postTripsHeightRef.current = postTripsRect && postTripsRect.height;

    if (postTripsRect && !postTripsFixed && !postTripsFixedBottom) {
      if (postTripsPosition === PostTripsPosition.Top) {
        postTripsBottomRef.current = postTripsRect.bottom + window.scrollY;
      }

      if (postTripsPosition === PostTripsPosition.Bottom) {
        postTripsFixedTopRef.current = postTripsRect.top + window.scrollY;

        const style = window.getComputedStyle(postTripsEl);

        postTripsMarginRef.current = parseInt(style.marginTop || '0', 10);
      }
    }
  }, [postId, postTripsFixed, postTripsFixedBottom, postTripsPosition]);

  const setDimensions = useCallback(() => {
    if (!postId) return;

    viewportWidthRef.current = document.documentElement.clientWidth;

    const navEl = document.getElementById('nav');
    const navRect = navEl && navEl.getBoundingClientRect();
    if (navRect) {
      navHeightRef.current = navRect.height;
    }

    const contentEl = document.getElementById('single-post-content');
    const contentRect = contentEl && contentEl.getBoundingClientRect();
    if (contentRect) {
      contentBottomRef.current = contentRect.bottom + window.scrollY;
    }

    const nextPrevEl = document.getElementById('next-prev');
    const nextPrevRect = nextPrevEl && nextPrevEl.getBoundingClientRect();
    if (nextPrevRect) {
      nextPrevHeightRef.current = nextPrevRect.height;
    }
  }, [postId]);

  const handleScroll = useCallback(() => {
    if (
      typeof navHeightRef.current !== 'number' ||
      typeof postTripsBottomRef.current !== 'number' ||
      !viewportWidthRef.current ||
      viewportWidthRef.current <= 800
    ) {
      return null;
    }

    const scroll = window.scrollY;

    const movePos =
      postTripsBottomRef.current &&
      postTripsBottomRef.current - navHeightRef.current;

    if (scroll > movePos && postTripsPosition !== PostTripsPosition.Bottom) {
      setPostTripsPosition(PostTripsPosition.Bottom);
      setPostTripsHeight(postTripsHeightRef.current);
    } else if (
      scroll <= movePos &&
      postTripsPosition !== PostTripsPosition.Top
    ) {
      setPostTripsPosition(PostTripsPosition.Top);
    }

    if (
      typeof postTripsMarginRef.current !== 'number' ||
      typeof contentBottomRef.current !== 'number' ||
      typeof postTripsHeightRef.current !== 'number' ||
      typeof postTripsFixedTopRef.current !== 'number'
    )
      return;

    const fixedStart =
      postTripsFixedTopRef.current -
      navHeightRef.current -
      postTripsMarginRef.current;

    const fixedEnd =
      contentBottomRef.current -
      (postTripsHeightRef.current +
        navHeightRef.current +
        postTripsMarginRef.current);

    if (scroll > fixedStart && scroll < fixedEnd && postTripsFixed !== true) {
      setPostTripsFixed(true);
      setPostTripsFixedBottom(false);
    } else if (
      (scroll <= fixedStart || scroll >= fixedEnd) &&
      postTripsFixed !== false
    ) {
      setPostTripsFixed(false);
      setPostTripsFixedBottom(
        scroll >= fixedEnd ? nextPrevHeightRef.current : false,
      );
    }
  }, [postTripsPosition, postTripsFixed]);

  const throttledScrollHandler = useMemo(
    () => throttle(handleScroll, 100),
    [handleScroll],
  );

  const handleResize = useCallback(() => {
    setDimensions();
    setPostTripsDimensions();
  }, [setDimensions, setPostTripsDimensions]);

  useEffect(() => {
    setPostTripsDimensions();
  }, [postTripsPosition, setPostTripsDimensions]);

  const throttledResizeHandler = useMemo(
    () => throttle(handleResize, 100),
    [handleResize],
  );

  useEffect(() => {
    if (postId) {
      window.addEventListener('scroll', throttledScrollHandler);
      window.addEventListener('resize', throttledResizeHandler);
    } else {
      window.removeEventListener('scroll', throttledScrollHandler);
      window.removeEventListener('resize', throttledResizeHandler);
    }
    return () => {
      window.removeEventListener('scroll', throttledScrollHandler);
      window.removeEventListener('resize', throttledResizeHandler);
    };
  }, [postId, throttledScrollHandler, throttledResizeHandler]);

  const postTrips = postId ? (
    <PostTrips
      key='post-trips-box'
      onSizeChange={handleResize}
      isFixed={postTripsFixed}
      fixedBottom={postTripsFixedBottom}
      postId={postId}
      ref={postTripsRef}
    />
  ) : null;

  return (
    <aside className={classNames('sidebar post-sidebar', styles.sidebar)}>
      {postTripsPosition === PostTripsPosition.Top ? postTrips : null}

      {postTripsPosition !== PostTripsPosition.Top &&
        isSinglePost &&
        hasTrips && <PostTripsSpacer height={postTripsHeight} />}

      {hasAbout && <About />}

      <Destinations />

      {postTripsPosition === PostTripsPosition.Bottom && postTrips}
    </aside>
  );
}
