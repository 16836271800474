import Link from 'next/link';
import { parseISO } from 'date-fns';

import { PostTripNextPrevFieldsFragment } from 'src/generated';
import { getFormattedDateRange } from 'src/utils';

import styles from './styles.module.scss';

type Props = {
  node: PostTripNextPrevFieldsFragment;
  isNext?: boolean;
};

export default function NextPrevItem({ node, isNext = false }: Props) {
  const trip = node.blogTripByNodePtrId;

  if (!trip) return null;

  const link = (
    <Link href={node.urlPath}>
      {trip.name}(
      {getFormattedDateRange(parseISO(trip.startDate), parseISO(trip.endDate))})
    </Link>
  );

  return (
    <h5 className={styles['next-prev-item']}>
      {isNext ? 'Next' : 'Previous'} trip: {link}
    </h5>
  );
}
