import React from 'react';
import classNames from 'classnames';

import { subscribe } from 'src/config/siteInfo';

import IconLinks, { Props } from 'src/components/IconLinks';

export default function SubscribeLinks({
  className,
  ...props
}: Partial<Props>) {
  return (
    <IconLinks
      className={classNames('subscribe-links', className)}
      links={subscribe.methods}
      byId={subscribe.byId}
      {...props}
    />
  );
}
