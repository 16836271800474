import { HTMLAttributes } from 'react';

import styles from './styles.module.scss';

export default function RibbonLinks({
  children,
  ...props
}: HTMLAttributes<HTMLOListElement>) {
  return (
    <ol className={styles['ribbon-links']} {...props}>
      {children}
    </ol>
  );
}
