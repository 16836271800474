import React, { SVGProps, useState } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import styles from './styles.module.scss';

type Props = SVGProps<SVGGElement> & {
  slug?: string;
  isVisited?: boolean;
  link?: string;
};

export default function Continent({
  slug,
  isVisited,
  children,
  link,
  ...gProps
}: Props) {
  const [hovered, setHovered] = useState(false);
  const router = useRouter();
  return (
    <g
      id={slug}
      className={classNames(
        'continent',
        {
          visited: isVisited,
          hovered,
          linked: !!link,
        },
        styles.continent,
      )}
      onClick={(e) => {
        e.preventDefault();
        if (link) {
          router.push(link).catch(console.error);
        }
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      {...gProps}
    >
      {children}
    </g>
  );
}
