import { useQuery } from '@apollo/client';
import { useContext } from 'react';
import RibbonLinks from 'src/components/PostSidebar/components/RibbonLinks';
import RibbonLink from 'src/components/PostSidebar/components/RibbonLinks/RibbonLink';
import { SidebarQueryDocument } from 'src/generated';
import { layoutDataContext } from 'src/utils/LayoutData';

export default function LatestPlaces() {
  const { isLoaded } = useContext(layoutDataContext);
  const { data } = useQuery(SidebarQueryDocument, { skip: !isLoaded });

  if (!data?.latestPlaces || data.latestPlaces.length === 0) return null;

  return (
    <div className='latest-terms latest-places'>
      <h3>Recent Destinations</h3>

      {data.latestPlaces && (
        <RibbonLinks>
          {data.latestPlaces.map((place) =>
            place.nodePtr ? (
              <RibbonLink
                key={place.nodePtrId}
                link={place.nodePtr.urlPath}
                image={place.featuredImage}
                name={place.name}
              />
            ) : null,
          )}
        </RibbonLinks>
      )}
    </div>
  );
}
