import { HTMLAttributes } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

type Props = HTMLAttributes<HTMLDivElement> & {
  height?: number | null | undefined;
};

export default function Spacer({ className, height, ...props }: Props) {
  return (
    <div
      {...props}
      className={classNames(className, styles.spacer)}
      style={typeof height === 'number' ? { height: `${height}px` } : undefined}
    />
  );
}
